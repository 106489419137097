// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-update-template-js": () => import("./../src/templates/UpdateTemplate.js" /* webpackChunkName: "component---src-templates-update-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kcdc-guidelines-critical-care-guidelines-js": () => import("./../src/pages/kcdc-guidelines/critical-care-guidelines.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-critical-care-guidelines-js" */),
  "component---src-pages-kcdc-guidelines-drive-thru-manual-js": () => import("./../src/pages/kcdc-guidelines/drive-thru-manual.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-drive-thru-manual-js" */),
  "component---src-pages-kcdc-guidelines-guidelines-for-school-systems-js": () => import("./../src/pages/kcdc-guidelines/guidelines-for-school-systems.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-guidelines-for-school-systems-js" */),
  "component---src-pages-kcdc-guidelines-hospital-level-healthcare-facilities-guidelines-js": () => import("./../src/pages/kcdc-guidelines/hospital-level-healthcare-facilities-guidelines.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-hospital-level-healthcare-facilities-guidelines-js" */),
  "component---src-pages-kcdc-guidelines-management-of-healthcare-facilities-with-confirmed-cases-js": () => import("./../src/pages/kcdc-guidelines/management-of-healthcare-facilities-with-confirmed-cases.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-management-of-healthcare-facilities-with-confirmed-cases-js" */),
  "component---src-pages-kcdc-guidelines-operation-and-management-guidelines-for-inpatient-beds-js": () => import("./../src/pages/kcdc-guidelines/operation-and-management-guidelines-for-inpatient-beds.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-operation-and-management-guidelines-for-inpatient-beds-js" */),
  "component---src-pages-kcdc-guidelines-pediatric-care-guidelines-js": () => import("./../src/pages/kcdc-guidelines/pediatric-care-guidelines.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-pediatric-care-guidelines-js" */),
  "component---src-pages-kcdc-guidelines-response-guidelines-for-local-governments-js": () => import("./../src/pages/kcdc-guidelines/response-guidelines-for-local-governments.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-response-guidelines-for-local-governments-js" */),
  "component---src-pages-kcdc-guidelines-screening-center-operation-guide-js": () => import("./../src/pages/kcdc-guidelines/screening-center-operation-guide.js" /* webpackChunkName: "component---src-pages-kcdc-guidelines-screening-center-operation-guide-js" */),
  "component---src-pages-more-resources-contact-tracing-strategy-js": () => import("./../src/pages/more-resources/contact-tracing-strategy.js" /* webpackChunkName: "component---src-pages-more-resources-contact-tracing-strategy-js" */),
  "component---src-pages-more-resources-mobile-testing-truck-js": () => import("./../src/pages/more-resources/mobile-testing-truck.js" /* webpackChunkName: "component---src-pages-more-resources-mobile-testing-truck-js" */),
  "component---src-pages-more-resources-protocol-for-molecular-testing-js": () => import("./../src/pages/more-resources/protocol-for-molecular-testing.js" /* webpackChunkName: "component---src-pages-more-resources-protocol-for-molecular-testing-js" */),
  "component---src-pages-more-resources-routine-distancing-guidelines-js": () => import("./../src/pages/more-resources/routine-distancing-guidelines.js" /* webpackChunkName: "component---src-pages-more-resources-routine-distancing-guidelines-js" */),
  "component---src-pages-more-resources-sejong-city-drive-thru-manual-js": () => import("./../src/pages/more-resources/sejong-city-drive-thru-manual.js" /* webpackChunkName: "component---src-pages-more-resources-sejong-city-drive-thru-manual-js" */),
  "component---src-pages-more-resources-test-site-installation-guide-js": () => import("./../src/pages/more-resources/test-site-installation-guide.js" /* webpackChunkName: "component---src-pages-more-resources-test-site-installation-guide-js" */),
  "component---src-pages-more-resources-walk-thru-booth-design-js": () => import("./../src/pages/more-resources/walk-thru-booth-design.js" /* webpackChunkName: "component---src-pages-more-resources-walk-thru-booth-design-js" */)
}

